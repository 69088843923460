<template>
  <v-row class="my-12">
    <v-col cols="12">
      <div>
        <div class="bold-text font-size-h3">Manage API Credentials</div>
        <div class="dark-grey-text">
          These credentials are required to authenticate with the API.
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="9">
      <v-card outlined>
        <v-card-title>
          <div>
            <div class="bold-text font-size-h3">API Tokens</div>
            <div class="dark-grey-text text-body-1">
              These tokens allow you to deploy using our CLI or other automated
              tools.
            </div>
          </div>
          <v-spacer> </v-spacer>
          <v-btn
            v-if="!getInactiveUserMessage"
            id="createNewApiTokenBtn"
            @click="(showCreateForm = true), resetForm()"
            color="primary"
          >
            Create API Token
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="getTokenList"
                    single-expand
                    :loading="loading"
                    :expanded.sync="expanded"
                    hide-default-footer
                    class="
                      v-card v-sheet v-sheet--outlined
                      radius-10
                      release-table
                    "
                    mobile-breakpoint="1070"
                  >
                    <template #item="{ item, expand, isExpanded, index }">
                      <v-slide-y-transition>
                        <tr
                          id="createNewTokenForm"
                          v-show="showCreateForm && index < 1"
                        >
                          <td colspan="2" class="py-0">
                            <v-text-field
                              id="createTokenName"
                              v-model="formValues.name"
                              filled
                              v-validate="'required|max:27'"
                              counter="27"
                              v-bind="veeValidate('Name', '')"
                              placeholder="Name"
                            >
                            </v-text-field>
                          </td>
                          <td colspan="2" class="py-0">
                            <v-select
                              id="createTokenTeamSelect"
                              v-model="formValues.team_id"
                              item-text="name"
                              item-value="id"
                              :items="teamList"
                              v-validate="'required'"
                              v-bind="veeValidate('Team', '')"
                              filled
                              placeholder="Select Team"
                            >
                              <template slot="item" slot-scope="data">
                                <v-row no-gutters>
                                  <v-col
                                    class="d-flex align-center justify-center"
                                  >
                                    <v-list-item-title
                                      :id="`data-item`"
                                      v-text="data.item.name"
                                    />
                                  </v-col>
                                  <!-- <v-col cols="12">
                                    <v-pagination
                                      v-if="getTotalTeamCount > 4"
                                      flat
                                      id="paginationComponent"
                                      class="ml-auto"
                                      v-model="page"
                                      :length="totalLength || 0"
                                      :disabled="getTotalTeamCount < 4"
                                      :total-visible="0"
                                    ></v-pagination>
                                  </v-col> -->
                                </v-row>
                              </template>
                            </v-select>
                          </td>
                          <td
                            colspan="3"
                            class="py-0 text-right"
                            cols="12"
                            md="4"
                          >
                            <v-btn
                              color="primary"
                              outlined
                              id="createTokenCancel"
                              class="mx-2 text-transform-none"
                              @click="cancelCreateForm"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              id="createTokenSubmit"
                              class="text-transform-none"
                              depressed
                              :loading="loading"
                              :disabled="errors.any()"
                              @click="createAPIToken"
                              >Create</v-btn
                            >
                          </td>
                        </tr>
                      </v-slide-y-transition>

                      <tr class="py-3">
                        <td id="tokenName">
                          <div>
                            <MaxText :text="item.name" max="12"></MaxText>
                          </div>
                        </td>
                        <td class="teamName">
                          <MaxText
                            :text="item.team ? item.team.name : ''"
                            max="12"
                          ></MaxText>
                        </td>
                        <td>
                          <v-chip
                            color="grey"
                            class="black--text cursor-pointer"
                            @click="copy(item.token)"
                            label
                          >
                            {{ item.token }}
                          </v-chip>
                        </td>
                        <td class="pr-0">
                          <vue-hoverable-date
                            :date="item.created_at"
                          ></vue-hoverable-date>
                        </td>
                        <td class="pr-0">
                          <vue-hoverable-date
                            v-if="item.last_used"
                            :date="item.last_used"
                          ></vue-hoverable-date>
                          <span v-else>Never</span>
                        </td>
                        <td>
                          <span
                            :class="
                              item.status === 1
                                ? 'success--text'
                                : 'error--text'
                            "
                            >{{
                              item.status === 1 ? "Active" : "Inactive"
                            }}</span
                          >
                        </td>
                        <td>
                          <ActionDropdown v-if="!getInactiveUserMessage">
                            <template v-slot:list>
                              <!-- item in the drop down -->
                              <v-list-item
                                id="renameNameOption"
                                :disabled="item.status !== 1"
                                @click.stop="
                                  (changeText = item.name), expand(!isExpanded)
                                "
                                ><v-icon small>edit</v-icon>Rename Key
                              </v-list-item>
                              <v-list-item
                                id="removeTokenOption"
                                @click="removeToken(item.id, item.name)"
                                ><v-icon small>mdi-delete</v-icon>Delete Key
                              </v-list-item>
                            </template>
                          </ActionDropdown>
                        </td>
                      </tr>
                    </template>
                    <template slot="loading">
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="table-heading, list-item-two-line, image, table-tfoot"
                      ></v-skeleton-loader>
                    </template>
                    <template slot="no-data">
                      <v-row>
                        <v-col cols="12">
                          <v-slide-y-transition>
                            <v-row
                              class="my-3"
                              align="center"
                              v-show="showCreateForm"
                            >
                              <v-col colspan="2" class="py-0">
                                <v-text-field
                                  v-model="formValues.name"
                                  filled
                                  v-validate="'required|max:27'"
                                  v-bind="veeValidate('Name', '')"
                                  placeholder="Name"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col colspan="2" class="py-0">
                                <v-select
                                  v-model="formValues.team_id"
                                  item-text="name"
                                  item-value="id"
                                  :items="teamList"
                                  v-validate="'required'"
                                  v-bind="veeValidate('Team', '')"
                                  filled
                                  placeholder="Select Team"
                                ></v-select>
                              </v-col>
                              <v-col
                                colspan="3"
                                class="py-0 text-right"
                                cols="12"
                                md="4"
                              >
                                <v-btn
                                  color="primary"
                                  outlined
                                  class="mx-2 text-transform-none"
                                  @click="cancelCreateForm"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="primary"
                                  class="text-transform-none"
                                  depressed
                                  :loading="loading"
                                  :disabled="errors.any()"
                                  @click="createAPIToken"
                                  >Create</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-slide-y-transition>
                        </v-col>
                        <v-col v-if="showCreateForm" cols="12">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                          <inline-svg
                            class="svg-icon empty_icon"
                            src="/media/placeholders/error.svg"
                          />
                          <p
                            class="empty-title bold-text text-dark text-center"
                          >
                            No data to show
                          </p>
                        </v-col>
                      </v-row>
                    </template>
                    <template
                      #expanded-item="{ headers, item, expand, isExpanded }"
                    >
                      <td :colspan="headers.length" class="pa-0">
                        <v-card id="renameField" tile flat>
                          <v-card-text>
                            <v-row align="center">
                              <v-col cols="4">
                                <v-text-field
                                  v-model="changeText"
                                  id="changeNameField"
                                  filled
                                  v-validate="'required|max:27'"
                                  v-bind="veeValidate('Name', '')"
                                  placeholder="Name"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-btn
                                  outlined
                                  color="primary"
                                  :disabled="loading"
                                  @click.stop="expendRow(item)"
                                  class="mx-3 text-transform-none"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="primary"
                                  id="changeNameBtn"
                                  class="text-transform-none"
                                  depressed
                                  :loading="loading"
                                  @click="changeName(item)"
                                  >Save</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="3">
      <v-card outlined>
        <v-card-title>
          <div>
            <v-icon left v-text="'mdi-lightbulb-on'"></v-icon>
            <span class="bold-text font-size-h3"> About API tokens </span>
          </div>
        </v-card-title>
        <v-card-text>
          These credentials are required to authenticate with the API.
        </v-card-text>
      </v-card>
    </v-col>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 650 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="removeTokenModal"
      no-py
    >
      <template #message>
        <v-card-title class="font-weight-bold"> Delete API Token </v-card-title>
        <v-divider />

        <v-card-text class="message">
          <v-alert outlined color="warning" class="warning--text">
            Heads up! You're about to delete
            <b id="selectedName">{{ selectedName }}</b> credentials, used in the
            Development workspace.
          </v-alert>
          <div>
            Deleting these credentials will break any integrations that use
            them. Please be sure to update those integrations before deleting.
            This action <b>cannot</b> be undone.
          </div>
          <div class="my-3">
            If you're sure, please type in the exact name of the credentials to
            confirm.
          </div>
          <div>
            <v-text-field
              filled
              v-model="confirmRemoveName"
              placeholder="Name"
              id="confirmDeleteField"
              hint="This field is case sensitive."
            ></v-text-field>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class="main" @click="onCancelRemoveModal"> Cancel </v-btn>
          <v-btn
            class="text-transform-none"
            color="error"
            id="permanentDeleteToken"
            @click="confirmRemove"
            :disabled="selectedName !== confirmRemoveName"
            :loading="loading"
            >Permanently delete this credential</v-btn
          >
        </v-card-actions>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import {
  CREATE_API_TOKEN,
  DELETE_API_TOKEN,
  GET_API_TOKEN_LIST,
  UPDATE_API_TOKEN,
} from "@/store/users/settings.module.js";
import veeValidate from "@/mixins/veeValidate.js";
import { currentTimestamp } from "@/core/services/helper.service";
import { GET_TEAMS } from "@/store/team/manageTeam.module.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  mixins: [veeValidate],
  data() {
    return {
      showCreateForm: false,
      selectedName: "",
      showRenameField: false,
      selectedId: "",
      expanded: [],
      changeText: "",
      confirmRemoveName: "",
      removeTokenModal: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          width: "15%",
          value: "name",
        },
        {
          text: "Team Name",
          align: "left",
          sortable: false,
          width: "15%",
          value: "team_name",
        },
        {
          text: "API Token",
          align: "left",
          sortable: false,
          width: "15%",
          value: "token",
        },
        {
          text: "Created",
          align: "left",
          sortable: false,
          width: "17%",
          value: "created",
        },
        {
          text: "Last Used",
          align: "left",
          sortable: false,
          width: "17%",
          value: "last_used",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          width: "3%",
          value: "status",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          width: "3%",
          value: "action",
        },
      ],
      formValues: {
        name: "",
        team_id: "",
      },
      teamList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTokenList: "getTokenList",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
  },
  created() {
    this.getTokenLists();
    this.getTeamsList();
  },
  methods: {
    ...mapMutations({
      updateTokenList: "updateTokenList",
      removeTokenList: "removeTokenList",
      addNewToken: "addNewToken",
    }),
    getTokenLists() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_API_TOKEN_LIST)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.formValues = {
        name: "",
        team_id: "",
      };
      this.$validator.errors.clear();
    },
    async createAPIToken() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        this.$store
          .dispatch(CREATE_API_TOKEN, this.formValues)
          .then(({ data }) => {
            this.loading = false;
            this.showCreateForm = false;
            let dataToAdd = {
              id: data.data.id,
              name: this.formValues.name,
              token: data.data.token,
              last_used: 0,
              team: this.teamList[0],
              created_at: currentTimestamp(),
              status: 1,
            };
            this.addNewToken(dataToAdd);
            this.notifyUserMessage(data.data.message);
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    getTeamsList() {
      this.$store
        .dispatch(GET_TEAMS)
        .then((response) => {
          this.teamList = response.data[0].data;
        })
        .catch(() => {});
    },
    editName(id) {
      this.selectedId = id;
      this.showRenameField = true;
    },
    onCancelRemoveModal() {
      this.removeTokenModal = false;
      this.confirmRemoveName = "";
      this.selectedName = "";
      this.selectedId = "";
    },
    cancelCreateForm() {
      this.showCreateForm = !this.showCreateForm;
      this.resetForm();
    },
    changeName(item) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(UPDATE_API_TOKEN, { name: this.changeText, id: item.id })
        .then(({ data }) => {
          this.expendRow(item);
          this.loading = false;
          this.notifyUserMessage(data.data.message);
          this.confirmRemoveName = "";
          let dataToUpdate = {
            name: this.changeText,
            id: item.id,
          };
          this.updateTokenList(dataToUpdate);
          this.showRenameField = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    confirmRemove() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_API_TOKEN, this.selectedId)
        .then(({ data }) => {
          this.removeTokenList(this.selectedId);
          this.removeTokenModal = false;
          this.confirmRemoveName = "";
          this.loading = false;
          this.notifyUserMessage(data.data.message);
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    expendRow(item) {
      if (!item.value) {
        this.expanded = item.id === this.expanded[0].id ? [] : [item];
      }
    },
    removeToken(id, name) {
      this.selectedId = id;
      this.changeText = "";
      this.selectedName = name;
      this.removeTokenModal = true;
    },
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.notifyUserMessage({
          message: "Copied successfully",
          timeout: true,
        });
      } catch (error) {
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.warning--text {
  color: #8c6e00 !important;
}
</style>
